import React, { useState, useEffect } from "react";
import TamblExamTimetable from "./TamblExamTimetable";
import generalQuery from "../generalQuery";
const Exams = () => {
  const [classes, setClasses] = useState([]);
  const [datesOptions, setDatesOptions] = useState([]);
  const [classroom, setClassroom] = useState("");
  const [sub, setSub] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [dates, setDates] = useState([]);
  const [teachersub, setTeachersub] = useState(sessionStorage.getItem("sub"));
  const [data, setData] = useState([]);

  const getClassesForExams = async (x) => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getClassesForExams",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      const arr = [];
      resp.forEach((e) => {
        if (arr.indexOf(e.class) === -1) {
          arr.push(e.class);
        }
      });
      setClasses(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const getExams = async (x) => {
    try {
      const body = { classroom: x };
      const response = await fetch("https://mut.herokuapp.com/getExams", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      const arr = [];
      resp.forEach((e) => {
        if (e.arabic !== "null") {
          arr.push(e.arabic);
        }
        if (e.english !== "null") {
          arr.push(e.english);
        }
        if (e.islamic !== "null") {
          arr.push(e.islamic);
        }
        if (e.math !== "null") {
          arr.push(e.math);
        }
        if (e.science !== "null") {
          arr.push(e.science);
        }
        if (e.socialst !== "null") {
          arr.push(e.socialst);
        }
        if (e.History !== "null") {
          arr.push(e.history);
        }
        if (e.Sports !== "null") {
          arr.push(e.sports);
        }
        if (e.comp !== "null") {
          arr.push(e.comp);
        }
      });
      setDatesOptions(arr);
      // setExams(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const updateExamDate = async (x) => {
    try {
      const body = { classroom, sub, exDate: x };
      const response = await fetch("https://mut.herokuapp.com/updateExamDate", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      getExams(classroom);
    } catch (error) {
      console.log(error);
    }
  };
  const getDate = (r, dt) => {
    if (r[dt]) {
      // return new Date("2023-" + r[dt].slice(-1) + "-" + r[dt].slice(0, 2));
      return r[dt];
    } else {
      return "";
    }
  };
  useEffect(() => {
    getClassesForExams();
    // if (teachersub === "Math") {
    //   setDates([
    //     "27/05/2024",
    //     "28/05/2024",
    //     "29/05/2024",
    //     "30/05/2024",
    //     "31/05/2024",
    //   ]);
    // } 
    // else if (teachersub === "English") {
    //   setDates([
    //     "27/05/2024",
    //     "28/05/2024",
    //     "29/05/2024",
    //     "30/05/2024",
    //     "31/05/2024",
    //   ]);
    // }
    // else if (teachersub === "Science") {
    //   setDates([
    //     "13/05/2024",
    //     "14/05/2024",
    //     "15/05/2024",
    //     "16/05/2024",
    //     "17/05/2024",
    //   ]);
    // }
    // else
    //  if (teachersub === "Arabic") {
    //   setDates([
    //     "27/05/2024",
    //     "28/05/2024",
    //     "29/05/2024",
    //     "30/05/2024",
    //     "31/05/2024",
    //   ]);
    // }
    // else if (teachersub === "Gov" ||teachersub === "Comp"||teachersub === "PE") {
    //   setDates([
    //     "06/05/2024",
    //     "07/05/2024",
    //     "08/05/2024",
    //     "09/05/2024",
    //     "10/05/2024",
    //     "13/05/2024",
    //     "14/05/2024",
    //     "15/05/2024",
    //     "16/05/2024",
    //     "17/05/2024",
    //   ]);
    // }
    setDates([]);
    // else {
    //   setDates([]);
    // }
  }, []);

  if (successMessage) {
    return <div>hi</div>;
  }
  return (
    <div>
      <h2>MAP/TALA Scheduling </h2>
      <h2>Spring 2024 </h2>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            getExams(e.target.value);
            setClassroom(e.target.value);
            generalQuery(
              `select * from exams where class='${e.target.value}'`
            ).then((results) => {
              setData(
                [
                  ["Arabic", getDate(results[0], "arabic")],
                  ["English", getDate(results[0], "english")],
                  ["Math", getDate(results[0], "math")],
                  ["Science", getDate(results[0], "science")],
                  ["Islamic St", getDate(results[0], "islamic")],
                  ["Social St", getDate(results[0], "socialst")],
                  ["Technology", getDate(results[0], "comp")],
                  ["History", getDate(results[0], "history")],
                  ["Drama", getDate(results[0], "drama")],
                  ["Sports", getDate(results[0], "sports")],
                ]
                  .filter((a) => a[1] !== "")
                  .sort((a, b) => (a[1] > b[1] ? 1 : -1))
              );
            });
          }}
        >
          <option value="">Class.....الشعبة</option>
          {classes.map((c) => {
            return <option key={c}>{c}</option>;
          })}
        </select>
        <select
          className="select"
          onChange={(e) => {
            setSub(e.target.value);
          }}
        >
          <option value="">Select Your Subject المادة</option>
          {teachersub === "Arabic" ? (
            <option value="arabic">Arabic</option>
          ) : teachersub === "English" ? (
            <option value="english">English</option>
          ) : teachersub === "Math" ? (
            <option value="math">Math</option>
          ) : teachersub === "Science" ? (
            <option value="science">Science</option>
          ) : teachersub === "Comp" ? (
            <option value="comp">Computer Sc</option>
          ) : teachersub === "Gov" ? (
            <option value="History">History</option>
          ) : teachersub === "Drama" ? (
            <option value="Drama">Drama</option>
          ) : teachersub === "PE" ? (
            <option value="Sports">Sports</option>
          ) : (
            <>
              <option value="socialst">Social St</option>
              <option value="islamic">اسلامية</option>
            </>
          )}
        </select>
        {sub !== "" && (
          <div style={{ marginTop: "50px" }}>
            {dates.map((d) => {
              if (datesOptions.indexOf(d) === -1) {
                return (
                  <button
                    style={{
                      padding: "12px",
                      backgroundColor: "greenyellow",
                      margin: "0 5px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={(c) => {
                      updateExamDate(d);

                      generalQuery(
                        `select * from exams where class='${classroom}'`
                      ).then((results) => {
                        // setInterval(() => {

                        // }, 2000);
                        setData(
                          [
                            ["Arabic", getDate(results[0], "arabic")],
                            ["English", getDate(results[0], "english")],
                            ["Math", getDate(results[0], "math")],
                            ["Science", getDate(results[0], "science")],
                            ["Islamic St", getDate(results[0], "islamic")],
                            ["Social St", getDate(results[0], "socialst")],
                            ["History", getDate(results[0], "history")],
                            ["Drama", getDate(results[0], "drama")],
                            ["Sports", getDate(results[0], "sports")],
                          ]
                            .filter((a) => a[1] !== "")
                            .sort((a, b) => (a[1] > b[1] ? 1 : -1))
                        );
                      });
                    }}
                  >
                    {d}
                  </button>
                );
              } else {
                return (
                  <span
                    style={{
                      padding: "12px",
                      backgroundColor: "pink",
                      margin: "0 5px",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    {d}
                  </span>
                );
              }
            })}
          </div>
        )}
      </div>
      {data.length > 0 && <TamblExamTimetable data={data} />}
    </div>
  );
};

export default Exams;
