import React, { useState, useEffect } from "react";
import "../views/home.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import HeaderHome from "./HeaderHome";
import CircularProgress from "@material-ui/core/CircularProgress";
import Manager from "../views/Manager";
import Registrar from "./Registrar";
import Home from "./home";
import Portal from "./Portal";
import Pec from "./Pec";
import Cobra from "./Cobra";
import TeacherNew from "./TeacherNew";
import SuperAdmin from "./SuperAdmin";
import RegistrarHome from "./RegistrarHome";
import generalQuery from "../generalQuery";
import SpecialTimetable from "./SpecialTimetable";
const Newlogin = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  // const [portalSwitch, setPortalSwitch] = useState(true);
  const [role, setRole] = useState("");
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const getuser = async () => {
    setLoading(true);
    try {
      const body = { lemail: user, lpassword: pass };
      const response = await fetch("https://mut.herokuapp.com/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      console.log(response);
      var formattedDate = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      generalQuery(
        `INSERT INTO loginStamp (user_id, login_time,name) VALUES ('${user.length}', '${formattedDate}','${resp.teachername}')`
      );

      sessionStorage.setItem("user", resp.lname);
      sessionStorage.setItem("email", resp.lemail);
      sessionStorage.setItem("sub", resp.sub);
      sessionStorage.setItem("trole", resp.trole);
      sessionStorage.setItem("teachername", resp.teachername);
      sessionStorage.setItem("intervsubject", resp.intervsubject);
      sessionStorage.setItem("intervgroup", resp.intervgroup);
      sessionStorage.setItem("intervroom", resp.intervroom);
      console.log(sessionStorage.getItem("user"));
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("Incorrect Username and/or Password");
      setLoading(false);
    }
  };

  if (sessionStorage.getItem("user") === "teacher") {
    return <TeacherNew />;
  }
  if (sessionStorage.getItem("user") === "admin") {
    return <Manager />;
  }
  if (sessionStorage.getItem("user") === "Cobra") {
    // return <Timetable />;
    // return <Sub2 />;
    return <Cobra />;
  }
  if (sessionStorage.getItem("user") === "registrar") {
    return <RegistrarHome />;
  }
  if (sessionStorage.getItem("user") === "student") {
    return <Home />;
  }
  if (sessionStorage.getItem("user") === "pec") {
    return <Pec />;
  }
  if (sessionStorage.getItem("user") === "super") {
    return <SuperAdmin />;
  }
  if (sessionStorage.getItem("user") === "timetable") {
    return <SpecialTimetable />;
  }
  const StudentsDataLogin = async () => {
    setLoading(true);
    try {
      const body = { esis: user, eid: pass };
      const response = await fetch(
        "https://mut.herokuapp.com/StudentsDataLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      var formattedDate = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      generalQuery(
        `INSERT INTO loginStamp (user_id, login_time,name,homeroom) VALUES ('${user}', '${formattedDate}','${resp.englishname}','${resp.homroom}');`
      );
      sessionStorage.setItem("user", resp.role);
      sessionStorage.setItem("esis", resp.studentnumber);
      sessionStorage.setItem("id", resp.id);
      sessionStorage.setItem("grade", resp.homroom);
      sessionStorage.setItem("reportElig", resp.reportelig);

      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("Please, check your eSIS or EID!");
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <CircularProgress />
      </div>
    );
  }
  if (role === "mutanabi") {
    return (
      <div className="container">
        <SpecialTimetable />
      </div>
    );
  }
  return (
    <div className="newSignin">
      <HeaderHome />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username/رقم الطالب"
            name="text"
            autoComplete="email"
            autoFocus
            onChange={(e) => {
              setUser(e.target.value.toLowerCase());
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password/ رقم الهوية "
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => {
              setPass(e.target.value);
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              if (user === "timetable6023@") {
                generalQuery(
                  `select * from login where lemail='${user}' and lpassword='${pass}'`
                ).then((resp) => {
                  console.log(resp)
                  sessionStorage.setItem("user", resp[0].lname);
                  setRole(resp[0].lname)
                });
              } else if (user.indexOf(".") === -1 && user.indexOf("_") === -1) {
                StudentsDataLogin();
              } else {
                getuser();
              }
            }}
          >
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Newlogin;
