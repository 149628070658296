import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./attendanceTakingTracker.css";
import SyncIcon from "@mui/icons-material/Sync";
import AttendanceDashboard from "./AttendanceDashboard";

const AttendanceTakingTracker = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [refresher, setRefresher] = useState();
  const [day, setDay] = useState("");
  const [daysForMapping, setDaysForMapping] = useState([]);
  const [passedDate, setpassedDate] = useState(new Date().toISOString().slice(0, 10).replace("-", "").replace("-", ""));

  const generalQuery = async (z, p) => {
    setTeachers([]);
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      if (p === "getTeachers") {
        setTeachers(resp);
      }
      if (p === "getConf") {
        let classesArr = [];
        let dataArr = [];
        resp.forEach((el) => {
          if (classesArr.indexOf(el.homeroomcode) === -1) {
            classesArr.push(el.homeroomcode);
            dataArr.push(el);
          }
        });
        setData(
          dataArr.sort((a, b) => (a.homeroomcode > b.homeroomcode ? 1 : -1))
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = (date) => {
    let SelectedDay = new Date(date).toDateString().slice(0, 3);
    setDaysForMapping([
      "p1conf",
      "p2conf",
      "p3conf",
      "p4conf",
      "p5conf",
      "p6conf",
    ]);
    setDay(SelectedDay);
    if (SelectedDay === "Mon") {
      generalQuery(
        `select teacher,m1 as p1,m2 as p2,m3 as p3, m4 as p4, m5 as p5, m6 as p6 from teacherssummary`,
        "getTeachers"
      );
    }
    if (SelectedDay === "Tue") {
      generalQuery(
        `select teacher,t1 as p1,t2 as p2,t3 as p3, t4 as p4, t5 as p5, t6 as p6 from teacherssummary`,
        "getTeachers"
      );
    }
    if (SelectedDay === "Wed") {
      generalQuery(
        `select teacher,w1 as p1,w2 as p2,w3 as p3, w4 as p4, w5 as p5, w6 as p6 from teacherssummary`,
        "getTeachers"
      );
    }
    if (SelectedDay === "Thu") {
      generalQuery(
        `select teacher,th1 as p1,th2 as p2,th3 as p3, th4 as p4, th5 as p5, th6 as p6 from teacherssummary`,
        "getTeachers"
      );
    }
    if (SelectedDay === "Fri") {
      generalQuery(
        `select teacher,f1 as p1,f2 as p2,f3 as p3, f4 as p4 from teacherssummary`,
        "getTeachers"
      );

      setDaysForMapping(["p1conf", "p2conf", "p3conf", "p4conf"]);
    }
    generalQuery(
      `select homeroomcode, p1conf,p2conf,p3conf,p4conf,p5conf,p1conf,p6conf from ${
        "zatt" + date.replace("-", "").replace("-", "")
      }`,
      "getConf"
    );
  };
  useEffect(() => {
    document.getElementById("inputDate").value = new Date()
      .toISOString()
      .slice(0, 10);
    getData(new Date().toISOString().slice(0, 10));
  }, [refresher]);
  return (
    <div>
      <h3>Attendance Monitoring System</h3>

      <div className="container">
        <input
          type="date"
          id="inputDate"
          onChange={(e) => {
            if (
              new Date(e.target.value).toDateString().slice(0, 3) !== "Sat" &&
              new Date(e.target.value).toDateString().slice(0, 3) !== "Sun"
            ) {
              getData(e.target.value);
              setpassedDate(e.target.value.replace("-", "").replace("-", ""));
            } else {
              alert("Happy Weekend");
            }
          }}
        />
        <div
          className="select"
          style={{ cursor: "pointer", marginTop: "10px", color: "darkblue" }}
          onClick={() => {
            setRefresher(Math.random());
            setpassedDate((new Date().toISOString().slice(0, 10).replace("-", "").replace("-", "")))
          }}
        >
          <SyncIcon fontSize="large" />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="container">
            <>
              {new Date().toDateString().slice(0, 3) === "Sat" ||
              new Date().toDateString().slice(0, 3) === "Sun" ? (
                <div style={{ fontSize: "25px" }}>
                  Happy Weekend! {"\u{1F600}"}
                </div>
              ) : (
                <CircularProgress />
              )}
            </>
          </div>
        ) : (
          <div className="container">
            {data.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <AttendanceDashboard passedDate={passedDate} />
                <table className="attendanceTracker">
                  <thead>
                    <tr>
                      <th>Class</th>
                      <th>P1</th>
                      <th>P2</th>
                      <th>P3</th>
                      <th>P4</th>
                      {day !== "Fri" && (
                        <>
                          <th>P5</th>
                          <th>P6</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .filter((f) => f.homeroomcode !== "null")
                      .map((d) => {
                        return (
                          <tr key={d.homeroomcode}>
                            <td>{d.homeroomcode}</td>
                            {daysForMapping.map((e) => {
                              return (
                                <td
                                  className={
                                    d[e] === "taken"
                                      ? "greenBackGround"
                                      : "redBackGround"
                                  }
                                >
                                  {
                                    <>
                                      <a
                                        href=""
                                        onClick={(e) => {
                                          e.preventDefault();
                                          console.log();
                                        }}
                                      >
                                        {teachers
                                          .filter(
                                            (t) =>
                                              t[e.slice(0, 2)] ===
                                              d.homeroomcode
                                                .substring(1)
                                                .split("-")[0] +
                                                d.homeroomcode
                                                  .substring(1)
                                                  .split("-")[1]
                                          )[0]
                                          ?.teacher.slice(0, 15)}
                                      </a>
                                    </>
                                  }
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceTakingTracker;
