// import Admin from "./views/admin";
// import Reports from "./views/classLists/Reports";
// import Home from "./views/home";
// import Recordabsentstudents from "./views/RecordAbsentStudents";
// import Subs from "./views/Subs";
// import Sub2 from "./views/Sub2";
import CreditHoursTracker from "./CreditHoursTracker";
import IndvidualCreditHours from "./views/IndvidualCreditHours";
import Newlogin from "./views/NewLogin";
// import Pecattendance from "./views/PecAttendance";
// import AttEntry from "./AttEntry";
// import TeachersAbsCounters from "./views/TeachersAbsCounters";
// import SENMarksEntry from "./views/SENMarksEntry";
// import AdekFinalReporting from "./views/ADEKfinalReporting";
// import Makeup from "./Makeup";
// import Resit from "./Resit";
// import MarksSheet from "./views/MarksSheet";
// import CreatorTimetable from "./CreatorTimetable";
// import CoverageNew from "./CoverageNew";
// import StudentListEdit from "./views/StudentListEdit";
// import AddStudentData from "./views/AddStudentData";
// import SuperAdmin from "./views/SuperAdmin";
// import AssessmentCreator from "./components/exams/AssessmentCreator";
// import Exams from "./views/Exams";
// import Examtimetable from "./views/ExamTimetable";
// import StudentsPerformance from "./components/exams/StudentsPerformance";
// import Ccss from "./components/curriculum/Ccss";
// import SuperadminFinalChecker from "./views/SuperadminFinalChecker";
// import AssessmentManager from "./views/AssessmentManager";
// import Test from "./views/Test";
// import StandardsUploader from "./components/exams/StandardsUploader";
// import TimetableCreator from "./components/timetable/TimetableCreator";
// import SuperadminPass from "./views/SuperadminPass";
// import SuperadminEndOfYear from "./views/SuperadminEndOfYear";
// import TeacherDashboard from "./map/TeacherDashboard";
// import MapAnalysis from "./map/MapAnalysis";
// import SuperadminEndOfYear23Export from "./views/SuperadminEndOfYear23Export";
// import TimetableNew from "./views/timetableNew";
// import TeacherMapReport from "./views/TeacherMapReport";

function App() {
  return (
    <div className="App">
      {/* <Ccss/> */}
      {/* <Admin /> */}
      {/* <Teacher/> */}
      {/* <Home /> */}
      {/* <Recordabsentstudents/> */}
      {/* <MarksEntry /> */}
      {/* <Sub2 /> */}
      {/* <Testreporting/> */}
      {/* <Subs/> */}
      {/* <Reports /> */}
      <Newlogin />
      {/* <IndvidualCreditHours /> */}
      {/* <CreditHoursTracker /> */}
      {/* <TeacherMapReport /> */}
      {/* <TimetableNew/> */}
      {/* <Timetable /> */}
      {/* <SuperadminEndOfYear23Export /> */}
      {/* <MapAnalysis /> */}
      {/* <SuperadminEndOfYear/> */}
      {/* <SuperadminPass/> */}
      {/* <TimetableCreator /> */}
      {/* <StandardsUploader/> */}
      {/* <StudentsAttendance/> */}
      {/* <Test/> */}
      {/* <AssessmentCreator/> */}
      {/* <StudentsPerformance /> */}
      {/* <AssessmentCreator /> */}
      {/* <AdekFinalReporting /> */}
      {/* <Pecattendance/> */}
      {/* <Pdffile /> */}
      {/* <AttEntry/> */}
      {/* <TeachersAbsCounters/> */}
      {/* <SENMarksEntry/> */}
      {/* <Resit />  */}
      {/* <Makeup/> */}
      {/* <MarksSheet /> */}
      {/* <Timetable/> */}
      {/* <CreatorTimetable/> */}
      {/* <CoverageNew /> */}
      {/* <StudentListEdit/> */}
      {/* <SuperAdmin/> */}
      {/* <Examtimetable /> */}
      {/* <AssessmentCreator/> */}
      {/* <SuperadminFinalChecker /> */}
      {/* <AssessmentManager/> */}
      {/* <Test/> */}
    </div>
  );
}

export default App;
