// ManageGroups.js
import React, { useState, useEffect } from "react";
import "./ManageGroups.css";
import generalQuery from "../../generalQuery";

const ManageGroups = () => {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({
    subject: "",
    teacherName: "",
    students: [],
  });
  const [editingGroup, setEditingGroup] = useState(null);
  const [newStudent, setNewStudent] = useState("");

  let subject = sessionStorage.getItem("sub");
  const teachername = sessionStorage.getItem("teachername");
  let group_name =
    teachername?.split(" ")[0].slice(0, 3) + teachername?.split(" ")[1];

  useEffect(() => {
    // Fetch existing groups from the backend
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    generalQuery(
      `select * from intervention_groups where teacher='${teachername}'`
    ).then((results) => {
      setGroups(results);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup({ ...newGroup, [name]: value });
  };

  const handleAddGroup = () => {
    // Add new group logic
    generalQuery(
      `INSERT INTO intervention_groups (subject, teacher, unique_name) VALUES ('${subject}','${teachername}','${
        subject + "_" + group_name + "_" + Number(groups.length + 1)
      }')`
    ).then(() => {
      fetchGroups();
    });
  };

  const handleAddStudent = (groupId) => {
    if (newStudent) {
      generalQuery(
        `INSERT INTO students (group_id, name) VALUES (${groupId}, '${newStudent}')`
      ).then(() => {
        fetchGroups();
        setNewStudent("");
      });
    }
  };

  const handleDeleteStudent = (studentId) => {
    generalQuery(`DELETE FROM students WHERE id=${studentId}`).then(() => {
      fetchGroups();
    });
  };

  const handleStartSession = (groupId) => {
    // Logic to start a new session and take attendance
  };

  return (
    <div className="manage-groups-container">
      <h2>Manage Groups</h2>
      <div className="new-group-form">
        <button onClick={handleAddGroup}>Add Group</button>
      </div>
      {groups.length > 0 && (
        <div className="groups-list">
          {groups.map((group) => (
            <div key={group.id} className="group-card">
              <h2>{group.unique_name}</h2>
              <p>Teacher: {teachername}</p>
              {/* <ul>
                {group.students.map((student) => (
                  <li key={student.id}>
                    {student.name}
                    <button
                      onClick={() => handleDeleteStudent(student.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul> */}
              <button
                onClick={() => setEditingGroup(group.id)}
                className="edit-button"
              >
                Edit
              </button>
              <button
                onClick={() => handleStartSession(group.id)}
                className="start-session-button"
              >
                Start Session
              </button>
              {editingGroup === group.id && (
                <div className="edit-group-form">
                  <input
                    type="text"
                    placeholder="Add Student"
                    value={newStudent}
                    onChange={(e) => setNewStudent(e.target.value)}
                  />
                  <button onClick={() => handleAddStudent(group.id)}>
                    Add Student
                  </button>
                  <button onClick={() => setEditingGroup(null)}>
                    Close
                  </button>
                </div>
              )}
              <button
                className="delete-group-button"
                onClick={() => {
                  generalQuery(
                    `delete from intervention_groups where unique_name='${group.unique_name}'`
                  ).then(() => {
                    fetchGroups();
                  });
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageGroups;
