import React, { useState, useEffect } from "react";
import generalQuery from "../../generalQuery";
import AssessmentCreator from "./AssessmentCreator";
import StandardsForm from "./StandardsForm";
import StandardsUploader from "./StandardsUploader";
import * as XLSX from "xlsx";

const StandardsData = () => {
  const [classes, setClasses] = useState([]);
  const [editable, setEditable] = useState(true);
  const [selectedSub, setSelectedSub] = useState(
    sessionStorage.getItem("sub")?.toLowerCase()
  );
  const [classNames, setClassNames] = useState([]);
  const [tests, setTests] = useState([]);
  const [terms, setTerms] = useState([]);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState("");
  const [term, setTerm] = useState("");
  const [test, setTest] = useState("");
  const [standerdsSet, setStanderdsSet] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [showform, setShowform] = useState(false);
  const [formData, setFormData] = useState([]);
  const [toEdit, setToEdit] = useState(false);
  const [toUpdate, setToUpdate] = useState("");
  const [total, setTotal] = useState(0);
  const [maxTotal, setMaxTotal] = useState(0);
  const [selectedStudentNumber, setSelectedStudentNumber] = useState();
  const [showAssessmentForm, setShowAssessmentForm] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const coreSub = ["Arabic", "English", "Math", "Science"];
  const [paulaData, setPaulaData] = useState([]);
  const [headers, setHeaders] = useState([]);
  function convertClassCode(code) {
    // Extract the number and the letter from the code
    const number = code.match(/\d+/)[0];
    const letter = code.match(/[a-zA-Z]+/)[0];

    // Convert to the new format
    return `G${number}-${letter}`;
  }

  const getMarksData = (e) => {
    setTotal(0);
    let sumMax = 0;
    generalQuery(
      `select * from assessmentsmarks where esis='${e.target.value}' and academic_year='${year}' and subject='${selectedSub}' and term='${term}' and assessment_type='${test}'`
    ).then((results) => {
      if (results.length > 0) {
        setToUpdate("y");
        let sum = 0;

        results.forEach((r) => {
          sum = sum + Number(r.grade);
          sumMax = sumMax + Number(r.max);
        });
        setTotal(sum);
        setMaxTotal(sumMax);
        setFormData(results);
      } else {
        setToUpdate("n");
        setToEdit(true);
        let stanSet = standerdsSet.filter(
          (s) =>
            s.assessment_type === test &&
            s.term === term &&
            s.academic_year === year
        );
        let st = classNames.filter(
          (s) => s.studentnumber === e.target.value
        )[0];
        stanSet.forEach((s) => {
          s.esis = st.studentnumber;
          s.name = st.englishname;
          s.arabicname = st.username;
          s.homeroom = st.homroom;
          sumMax = sumMax + Number(s.max);
        });
        setFormData(stanSet);
        setMaxTotal(sumMax);
      }
    });

    setShowform(true);
  };
  function extractNumber(str) {
    let matches = str.match(/\d+/);
    return matches ? parseInt(matches[0], 10) : null;
  }
  const ExportExcel = ({ data, selectedClass }) => {
    const exportToExcel = () => {

      const ws = XLSX.utils.json_to_sheet([headers, ...data], {
        skipHeader: true // Ensure no automatic headers are added
    });

    // Set your headers manually if not already set by [headers, ...data]
    let range = XLSX.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "1"; // Create cell address for the first row
        ws[address] = { v: headers[C], t: 's' }; // Set header value and type (string)
    }

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
      // Create a buffer
      let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

      // Create a Blob
      let blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create an anchor element and click it to download the file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = selectedClass + ".xlsx";
      a.click();
    };

    return (
      <button className="btn" onClick={exportToExcel}>
        Export to Excel
      </button>
    );
  };
  useEffect(() => {
    let arr = [];
    if (!coreSub.includes(sessionStorage.getItem("sub"))) {
      setEditable(false);
      generalQuery(
        `select distinct (homroom) from students order by homroom`
      ).then((results) => {
        results.forEach((e) => {
          arr.push(e.homroom);
        });

        setClasses(arr);
      });
    } else {
      generalQuery(
        `select distinct (homroom) from students order by homroom`
      ).then((results) => {
        results.forEach((e) => {
          arr.push(e.homroom);
        });

        setClasses(arr);
      });
      // if (sessionStorage.getItem("trole") === "hod") {

      // } else {
      //   generalQuery(
      //     `select * from teacherssummary  where teacher='${sessionStorage
      //       .getItem("teachername")
      //       ?.trim()}'`
      //   ).then((results) => {
      //     ["m", "t", "w", "th"].forEach((d) => {
      //       ["1", "2", "3", "4", "5", "6"].forEach((p) => {
      //         if (
      //           !arr.includes(results[0][d + p]) &&
      //           results[0][d + p] !== "null"
      //         ) {
      //           arr.push(results[0][d + p]);
      //         }
      //       });
      //     });
      //     setClasses(arr.sort((a, b) => (a > b ? 1 : -1)));
      //   });
      // }
    }
  }, []);
  function toProperCase(text) {
    return text.replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }
  return (
    <div>
      {showAssessmentForm ? (
        <StandardsUploader
          showAssessmentForm={showAssessmentForm}
          SettinghowAssessmentForm={(v) => setShowAssessmentForm(v)}
        />
      ) : (
        <div>
          <div className="container">
            {sessionStorage.getItem("trole") === "hod" &&
            coreSub.includes(sessionStorage.getItem("sub")) ? (
              <button
                className="select"
                onClick={() => {
                  {
                    setShowAssessmentForm(!showAssessmentForm);
                  }
                }}
              >
                Assessment Creator
              </button>
            ) : (
              ""
            )}

            {!editable && (
              <select
                disabled={showform ? true : false}
                className="select"
                onChange={(e) => {
                  setSelectedSub(e.target.value);
                }}
              >
                <option>---Sub---</option>
                {coreSub.map((c) => {
                  return (
                    <option key={c} value={c.toLowerCase()}>
                      {c}
                    </option>
                  );
                })}
              </select>
            )}
            <select
              disabled={showform ? true : false}
              className="select"
              onChange={(e) => {
                setSelectedClass(e.target.value);
                if (document.getElementById("testselect")) {
                  document.getElementById("testselect").value = "---Test---";
                }
                if (document.getElementById("termselect")) {
                  document.getElementById("termselect").value = "---Term---";
                }
                if (document.getElementById("yearselect")) {
                  document.getElementById("yearselect").value = "---Year---";
                }
                let sortKey = "englishname";
                if (sessionStorage.getItem("sub") === "Arabic") {
                  sortKey = "username";
                }

                generalQuery(
                  `select studentnumber,englishname,username,homroom,grade_level from students where homroom='${e.target.value}' order by ${sortKey}`
                ).then((results) => {
                  results.map((r) => (r.grade_level = r.grade_level.slice(-1)));
                  setClassNames(results);
                  generalQuery(
                    `select * from assessmentstandards where subject='${selectedSub}' and grade_level='${extractNumber(
                      e.target.value
                    )}'`
                  ).then((res) => {
                    setStanderdsSet(res);
                    let arrYear = [];
                    res.map((r) => {
                      if (arrYear.indexOf(r.academic_year) === -1) {
                        arrYear.push(r.academic_year);
                      }
                    });
                    setYears(arrYear);
                  });
                });
              }}
            >
              <option>---Class---</option>
              {classes.map((c) => {
                return (
                  <option key={c} value={c}>
                    {c}
                  </option>
                );
              })}
            </select>

            {classNames.length > 0 && (
              <>
                <select
                  disabled={showform ? true : false}
                  id="yearselect"
                  className="select"
                  onChange={(e) => {
                    let arr = [];
                    standerdsSet
                      .filter((s) => s.academic_year === e.target.value)
                      .forEach((s) => {
                        if (arr.indexOf(s.term) === -1) {
                          arr.push(s.term);
                        }
                        setTerms(arr);
                      });
                    setYear(e.target.value);
                  }}
                >
                  <option value="---Year---">---Year---</option>
                  {years.map((t) => {
                    return <option key={t}>{t}</option>;
                  })}
                </select>
                <select
                  disabled={showform ? true : false}
                  id="termselect"
                  className="select"
                  onChange={(e) => {
                    setTerm(e.target.value);
                    let arr = [];
                    standerdsSet
                      .filter((s) => s.term === e.target.value)
                      .forEach((s) => {
                        if (arr.indexOf(s.assessment_type) === -1) {
                          arr.push(s.assessment_type);
                        }
                        setTests(arr);
                      });
                  }}
                >
                  <option value="---Term---">---Term---</option>
                  {/* {terms.map((t) => {
                    return (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    );
                  })} */}
                  {/* <option value="Term 1"></option>
                  <option value="Term 2"></option> */}
                  {selectedClass.includes("7") ||
                  selectedClass.includes("8") ? (
                    <option value="Term 3">Term 3</option>
                  ) : (
                    <option value="Semester 2">Semester 2</option>
                  )}
                  {/* <option value="Term 1">Term 1</option> */}
                </select>
                <select
                  disabled={showform ? true : false}
                  id="testselect"
                  className="select"
                  onChange={(e) => {
                    setTest(e.target.value);
                  }}
                >
                  <option value="---Test---">---Test---</option>
                  {/* {tests.map((t) => {
                    return <option key={t}>{t}</option>;
                  })} */}
                  {/* <option value={"Key Assessment 4"}>Key Assessment 4</option> */}
                 
                  {selectedClass.includes("7") ||
                  selectedClass.includes("8") ? (
                    <option value={"End of Term 3"}>End of Term 3</option>
                  ) : (
                    <option value="End of Semester 2">End of Semester 2</option>
                  )}
              
                </select>
                <select
                  // disabled={showform ? true : false}
                  id="nameselect"
                  className="select"
                  onChange={(e) => {
                    getMarksData(e);
                    setSelectedStudentNumber(e.target.value);
                    setShowform(true);
                    for (let i = 0; i < formData.length; i++) {
                      if (document.getElementById("grade" + i)) {
                        document.getElementById("grade" + i).value = "";
                      }
                    }
                  }}
                >
                  <option>---- Names----</option>
                  {classNames.map((s) => {
                    return (
                      <option key={s.studentnumber} value={s.studentnumber}>
                        {sessionStorage.getItem("sub") === "Arabic"
                          ? s.username
                          : s.englishname}
                      </option>
                    );
                  })}
                </select>
                <>
                  <button
                    className="btn"
                    onClick={() => {
                      setPaulaData([]);
                      generalQuery(
                        `SELECT esis, name,q_division, homeroom, subject, max, grade, serial FROM public.assessmentsmarks where homeroom='${selectedClass}' and academic_year='${year}' and term='${term}' and assessment_type='${test}' and subject='${selectedSub}' order by name;`
                      ).then((res) => {
                        console.log(res);
                        let students = [];
                        let studentsArr = [];
                        res.forEach((s) => {
                          s.name = toProperCase(s.name);
                          if (students.indexOf(s.esis) === -1) {
                            students.push(s.esis);
                          }
                        });
                        students.forEach((sr) => {
                          let ind = res
                            .filter((r) => r.esis === sr)
                            .sort((a, b) => (a.serial > b.serial ? 1 : -1));
                          let headersArr = ["Name", "eSIS"];
                          ind.forEach((e) => {
                            if (headersArr.indexOf(e.q_division) === -1) {
                              headersArr.push(e.q_division);
                            }
                          });
                          setHeaders(headersArr);
                          let serials = {
                            0: ind[0].name,
                            1: ind[0].esis,
                          };
                          ind.forEach((o) => {
                            serials[Number(o.serial) + 1] = o.grade; // or whatever value you want to assign
                          });

                          const arr = Object.entries(serials);

                          // Step 2: Sort array
                          arr.sort((a, b) => Number(a[0]) - Number(b[0]));

                          // Optional Step 3: Convert back to object if needed
                          const sortedObj = Object.fromEntries(arr);

                          studentsArr.push(sortedObj);
                          setPaulaData(
                            studentsArr.sort((a, b) =>
                              a.name > b.name ? -1 : 1
                            )
                          );
                        });
                      });
                    }}
                  >
                    Paula's Data
                  </button>
                  {paulaData.length > 0 && (
                    <ExportExcel
                      data={paulaData}
                      selectedClass={selectedClass}
                    />
                  )}
                </>
              </>
            )}
          </div>
          <a
            href="https://drive.google.com/drive/folders/1py_W7Uv_axeb079xGuRAyRL-i1wm7U-E?usp=sharing"
            target="_blank"
          >
            Open Google Drive Folder
          </a>
          {showform ? (
            <StandardsForm
              settingShowForm={(v) => setShowform(v)}
              maxTotal={maxTotal}
              toUpdate={toUpdate}
              selectedSub={selectedSub}
              selectedStudentNumber={selectedStudentNumber}
              year={year}
              term={term}
              test={test}
              toEdit={toEdit}
              formData={formData}
              student={studentData}
              studentDataSetting={(v) => setStudentData(v)}
              showSetting={(v) => setShowform(v)}
              editable={editable}
              total={total}
              setTotal={(v) => {
                setTotal(v);
              }}
              formDataSetting={(v) => {
                setFormData(v);
              }}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default StandardsData;
