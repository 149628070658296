// TeacherDashboard.js
import React, { useState } from 'react';
import './TeacherDashboard.css';
import ManageGroups from './ManageGroups';

const TeacherDashboard = () => {
  const [activeTab, setActiveTab] = useState('manageGroups');

  return (
    <div className="dashboard-container">
      <h1>Teacher Dashboard</h1>
      <div className="tab-buttons">
        <button
          className={activeTab === 'manageGroups' ? 'active' : ''}
          onClick={() => setActiveTab('manageGroups')}
        >
          Manage Groups
        </button>
        <button
          className={activeTab === 'myGroups' ? 'active' : ''}
          onClick={() => setActiveTab('myGroups')}
        >
          My Groups
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'manageGroups' && <ManageGroups />}
        {activeTab === 'myGroups' && (
          <div className="my-groups">
            <h2>My Groups</h2>
            <p>Here are your current intervention groups.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherDashboard;
