import React, { useEffect, useState } from "react";
import "./MinimumExpectations.css"; // Import the styling sheet
import generalQuery from "../generalQuery";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress from Material-UI (or another loading spinner)
import Modal from "@mui/material/Modal"; // Import Modal
import Box from "@mui/material/Box"; // Box for Modal content
import IconButton from "@mui/material/IconButton"; // Button for closing modal
import CloseIcon from "@mui/icons-material/Close"; // Close Icon
import CheckIcon from "@mui/icons-material/Check"; // You can use any icon library (Material-UI example)

const MinimumExpectations = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [subjectFilter, setSubjectFilter] = useState("Reading");
  const [classFilter, setClassFilter] = useState("G8-A"); // Initial value set to G8-A
  const [testPercentileFilter, setTestPercentileFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State for modal open/close
  const [Targets, setTargets] = useState([]);
  const [domainFilter, setDomainFilter] = useState(""); // State for domain filter
  const [subdomainFilter, setSubdomainFilter] = useState(""); // State for subdomain filter
  const [availableSubdomains, setAvailableSubdomains] = useState([]); // State for available subdomains based on selected domain
  const [student, setStudent] = useState();
  const [iepdata, setIepdata] = useState([]);
  useEffect(() => {
    if (subjectFilter && classFilter) {
      setLoading(true);
      generalQuery(`select termname,studentid,student_name,classgroup,class,grade_level,subject,testritscore,testpercentile,teacher,eaw_t from student_growth_data
                          where subject = '${subjectFilter}' and class = '${classFilter}' order by student_name`)
        .then((results) => {
          // Convert testpercentile to number for proper sorting and filtering
          generalQuery(
            `select * from maptarges where course='${subjectFilter}' order by standard`
          )
            .then((targets) => {
              setTargets(targets);
              setDomainFilter(targets[0]?.domain || ""); // Set the initial domain filter to the first domain in the data
            })
            .catch((error) => console.error("Error fetching targets:", error));

          const processedResults = results.map((item) => ({
            ...item,
            testpercentile: parseInt(item.testpercentile, 10),
          }));
          setData(processedResults);
          setFilteredData(processedResults);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [subjectFilter, classFilter]);
  useEffect(() => {
    if (domainFilter) {
      const subdomains = Targets.filter(
        (item) => item.domain === domainFilter
      ).map((item) => item.subdomain);
      setAvailableSubdomains([...new Set(subdomains)]); // Set unique subdomains
      setSubdomainFilter(""); // Reset subdomain filter when domain changes
    }
  }, [domainFilter, Targets]);

  useEffect(() => {
    let filtered = data;
    if (testPercentileFilter) {
      if (testPercentileFilter === "<41") {
        filtered = data.filter((item) => item.testpercentile < 41);
      } else if (testPercentileFilter === ">41") {
        filtered = data.filter((item) => item.testpercentile > 41);
      } else if (testPercentileFilter === ">61") {
        filtered = data.filter((item) => item.testpercentile > 61);
      }
    }
    setFilteredData(filtered);
  }, [testPercentileFilter, data]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };
  const filteredTargets = Targets.filter((target) => {
    return (
      (!domainFilter || target.domain === domainFilter) &&
      (!subdomainFilter || target.subdomain === subdomainFilter)
    );
  });

  const handleOpenModal = () => {
    setModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };
  const handleRemoveToIEP = (student, target, id, index) => {
    generalQuery(
      `delete from iepdata where esis='${student.studentid}' and objectives='${target.objectives}'`
    ).then(() => {
      document.getElementById(id).style.display = "block";
      document.getElementById(index + "span").textContent = "";
      document.getElementById(index + "btn").style.display = "none";
    });
  };
  const handleAddToIEP = (student, target, id, index) => {
    // Construct the data to insert into iepdata
    const iepData = {
      esis: student.studentid,
      course: target.course,
      domain: target.domain,
      subdomain: target.subdomain,
      grade_level: target.grade_level,
      standard: target.standard,
      objectives: target.objectives,
      level: target.level,
      lowerrit: target.lowerrit,
      upperrit: target.uperrit,
    };

    // Insert the data into the iepdata table
    generalQuery(
      `INSERT INTO iepdata (esis, course, domain, subdomain, grade_level, standard, objectives, level, lowerrit, uperrit) VALUES ('${iepData.esis}','${iepData.course}','${iepData.domain}','${iepData.subdomain}','${iepData.grade_level}','${iepData.standard}','${iepData.objectives}','${iepData.level}','${iepData.lowerrit}','${iepData.upperrit}')`
    )
      .then((response) => {
        document.getElementById(id).style.display = "none";
        document.getElementById(index + "span").textContent = "Added to MGP";
        document.getElementById(index + "btn").style.display = "block";
      })
      .catch((error) => {});
  };
  return (
    <div className="minimum-expectations-container">
      <div className="filters">
        <select
          className="select-filter"
          value={subjectFilter}
          onChange={(e) => setSubjectFilter(e.target.value)}
        >
          <option value="">Select Subject</option>
          <option value="Language Usage">Language Usage</option>
          <option value="Reading">Reading</option>
          <option value="Math K-12">Math K-12</option>
          <option value="Science K-12">Science K-12</option>
        </select>

        <select
          className="select-filter"
          value={classFilter}
          onChange={(e) => setClassFilter(e.target.value)}
        >
          <option value="G8-A">G8-A</option>
          <option value="G8-B">G8-B</option>
          <option value="G8-C">G8-C</option>
          <option value="G8-D">G8-D</option>
          <option value="G8-E">G8-E</option>
          <option value="G8-F">G8-F</option>
          <option value="G8-G">G8-G</option>
          <option value="G8-H">G8-H</option>
          <option value="G8-I">G8-I</option>
          <option value="G8-J">G8-J</option>
          <option value="G9-A">G9-A</option>
          <option value="G9-B">G9-B</option>
          <option value="G9-C">G9-C</option>
          <option value="G9-D">G9-D</option>
          <option value="G9-E">G9-E</option>
          <option value="G9-F">G9-F</option>
          <option value="G9-G">G9-G</option>
          <option value="G9-H">G9-H</option>
          <option value="G9-I">G9-I</option>
          <option value="G9-J">G9-J</option>
          <option value="G9-K">G9-K</option>
          <option value="G10-A">G10-A</option>
          <option value="G10-B">G10-B</option>
          <option value="G10-C">G10-C</option>
          <option value="G10-D">G10-D</option>
          <option value="G10-E">G10-E</option>
          <option value="G10-F">G10-F</option>
          <option value="G10-G">G10-G</option>
          <option value="G10-H">G10-H</option>
          <option value="G11-A">G11-A</option>
          <option value="G11-B">G11-B</option>
          <option value="G11-C">G11-C</option>
          <option value="G11-D">G11-D</option>
          <option value="G11-E">G11-E</option>
          <option value="G11-F">G11-F</option>
        </select>

        <select
          className="select-filter"
          value={testPercentileFilter}
          onChange={(e) => setTestPercentileFilter(e.target.value)}
        >
          <option value="">Filter by Test Percentile</option>
          <option value="<41">Less than 41</option>
          <option value=">41">Greater than 41</option>
          <option value=">61">Greater than 61</option>
        </select>
      </div>

      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <table className="min-expectations-table">
          <thead className="min-expectations-table-header">
            {subjectFilter === "Language Usage" ||
            subjectFilter === "Reading" ? (
              <tr className="min-expectations-table-header-row">
                {[
                  "studentid",
                  "student_name",
                  "teacher",
                  "class",
                  "classgroup",
                  "eaw_t",
                  // "subject",
                  "testritscore",
                  "testpercentile",
                ].map((header) => (
                  <th
                    key={header}
                    onClick={() => handleSort(header)}
                    className="min-expectations-table-header-cell"
                  >
                    {header.replace("_", " ").toUpperCase()}
                    {sortConfig?.key === header &&
                      (sortConfig.direction === "ascending" ? " 🔼" : " 🔽")}
                  </th>
                ))}
              </tr>
            ) : (
              <tr className="min-expectations-table-header-row">
                {[
                  "studentid",
                  "student_name",

                  "class",

                  // "subject",
                  "testritscore",
                  "testpercentile",
                ].map((header) => (
                  <th
                    key={header}
                    onClick={() => handleSort(header)}
                    className="min-expectations-table-header-cell"
                  >
                    {header.replace("_", " ").toUpperCase()}
                    {sortConfig?.key === header &&
                      (sortConfig.direction === "ascending" ? " 🔼" : " 🔽")}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody className="min-expectations-table-body">
            {filteredData.map((row, index) => (
              <tr key={index} className="min-expectations-table-body-row">
                <td className="min-expectations-table-body-cell">
                  {row.studentid}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.student_name}
                  <br />
                  <div style={{ marginTop: "5px" }}>
                    <button
                      style={{
                        backgroundColor: "lightgreen",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIepdata([]);
                        setStudent(row);
                        handleOpenModal();
                        generalQuery(
                          `select objectives from iepdata where esis='${row.studentid}' and course='${subjectFilter}'`
                        ).then((iep) => {
                          let arr = [];
                          iep.forEach((i) => {
                            arr.push(i.objectives);
                          });
                          setIepdata(arr);
                        });
                      }}
                    >
                      MAP Targets
                    </button>
                    <button
                      style={{
                        backgroundColor: "orange",
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                      // onClick={() => {
                      //   setStudent(row);
                      //   handleOpenModal();
                      // }}
                    >
                      IEP Objectives
                    </button>
                  </div>
                </td>
                {(subjectFilter === "Language Usage" ||
                  subjectFilter === "Reading") && (
                  <td className="min-expectations-table-body-cell">
                    {row.teacher}
                  </td>
                )}
                <td className="min-expectations-table-body-cell">
                  {row.class}
                </td>
                {(subjectFilter === "Language Usage" ||
                  subjectFilter === "Reading") && (
                  <td className="min-expectations-table-body-cell">
                    {row.classgroup !== "null" && row.classgroup}
                  </td>
                )}
                {(subjectFilter === "Language Usage" ||
                  subjectFilter === "Reading") && (
                  <td className="min-expectations-table-body-cell">
                    {row.eaw_t !== "null" && row.eaw_t}
                  </td>
                )}

                <td className="min-expectations-table-body-cell">
                  {row.testritscore}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.testpercentile}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal with empty content */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="modal-box">
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="modal-title">MAP Growth Path</h2>

          {/* Filters for Domain and Subdomain */}
          <select
            value={domainFilter}
            onChange={(e) => setDomainFilter(e.target.value)}
          >
            <option value="">Select Domain</option>
            {[...new Set(Targets.map((target) => target.domain))].map(
              (domain, index) => (
                <option key={index} value={domain}>
                  {domain}
                </option>
              )
            )}
          </select>

          {domainFilter && (
            <select
              value={subdomainFilter}
              onChange={(e) => setSubdomainFilter(e.target.value)}
            >
              <option value="">Select Subdomain</option>
              {[
                ...new Set(
                  Targets.filter(
                    (target) => target.domain === domainFilter
                  ).map((target) => target.subdomain)
                ),
              ].map((subdomain, index) => (
                <option key={index} value={subdomain}>
                  {subdomain}
                </option>
              ))}
            </select>
          )}

          {/* Table for displaying filtered targets */}
          <table className="targets-table">
            <thead>
              <tr>
                <th>Grade Level</th>
                {/* <th>Standard</th> */}
                <th>Objective</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredTargets.map((target, index) => (
                <tr key={index}>
                  <td>{target.grade_level}</td>
                  {/* <td>{target.standard}</td> */}
                  <td>{target.objectives}</td>
                  <td>
                    {iepdata.includes(target.objectives) ? (
                      <>
                        <span id={index + "span"} style={{ color: "green" }}>
                          Added to MGP
                        </span>
                        <button
                          id={target.objectives + index}
                          style={{
                            backgroundColor: "lightblue",
                            display: "none",
                          }}
                          onClick={() => {
                            handleAddToIEP(
                              student,
                              target,
                              target.objectives + index,
                              index
                            );
                          }}
                        >
                          Add to MAP Growth Path
                        </button>
                        <button
                          id={index + "btn"}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            float: "right",
                          }}
                          onClick={() => {
                            handleRemoveToIEP(
                              student,
                              target,
                              target.objectives + index,
                              index
                            );
                          }}
                        >
                          X
                        </button>
                      </>
                    ) : (
                      <>
                        <span
                          style={{ color: "green" }}
                          id={index + "span"}
                        ></span>
                        <button
                          id={target.objectives + index}
                          style={{ backgroundColor: "lightblue" }}
                          onClick={() => {
                            handleAddToIEP(
                              student,
                              target,
                              target.objectives + index,
                              index
                            );
                          }}
                        >
                          Add to MAP Growth Path
                        </button>
                        <button
                          id={index + "btn"}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            float: "right",
                            display: "none",
                          }}
                          onClick={() => {
                            handleRemoveToIEP(
                              student,
                              target,
                              target.objectives + index,
                              index
                            );
                          }}
                        >
                          X
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
};

export default MinimumExpectations;
